import { cn } from './ui/utils';

export const GradientBorder: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className
}) => {
  return (
    <div
      className={cn('rounded-lg bg-gradient-to-tr from-primary to-blue-lighter p-[1px]', className)}
    >
      {children}
    </div>
  );
};
